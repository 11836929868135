import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Week from "../components/week"
import Schedule from "../components/schedule"
import FrontMatter from "../components/frontmatter"

// let week, dayModule;
//             if(active){
//               let singleValues = ["week", "order", "type", "date"];
//               let columnValues = ["topics", "due", "alerts"];
//               let doubleColumnLinkValues = [["reading", "readingLinks"],["assignments", "assignmentsLinks"],["talkingPoints", "talkingPointsLinks"],["optionalReading", "optionalReadingLinks"], ["links", "linksLinks"]];
//               dayModule = buildDayModule(sheetdata, singleValues, columnValues, doubleColumnLinkValues);
//               week = sheetdata[0].week;
//             }else if(dueOnly){
//               let singleValues = ["week", "order", "type", "date"];
//               let columnValues = ["due"];
//               let doubleColumnLinkValues = [];
//               dayModule = buildDayModule(sheetdata, singleValues, columnValues, doubleColumnLinkValues);
//               week = sheetdata[0].week;
//             }

//             if(weekModules[week] == undefined){
//               weekModules[week] = [dayModule];
//             }else{
//               weekModules[week].push(dayModule);
//               weekModules[week].sort((a,b)=>{
//                 return a.order - b.order;
//               })
//             }
let getColumns = (data, key) => {
  let d = data.map(row => row[key]).filter(r=>r!=undefined)
  return d;
}
let getColumnsWithLinks = (data, key, linkkey) => {
  return data.map(row => {
    console.log("row", row)
    return{
      text: row[key],
      link: row[linkkey]==null?undefined:row[linkkey]
    }
  }).filter(r=>r.text!=undefined);
}

// must haves
let daySchema = {
  alerts: [],
  due: [],
  talkingPoints:[],
  assignments: [],
  reading: [],
  optionalReading: [],
  links: [],
  topics: []
}


let buildDayModule = (sheetdata, singleValues, columnValues, doubleColumnLinkValues) => {
  let dayModule = {};
  singleValues.forEach(key=>{
    dayModule[key] = sheetdata[0][key]
  });
  columnValues.forEach(key=>{
    dayModule[key] = getColumns(sheetdata, key);
  });
  doubleColumnLinkValues.forEach(keys=>{
    console.log("leys", keys)
    let key1 = keys[0];
    let key2 = keys[1];
    dayModule[key1] = getColumnsWithLinks(sheetdata, key1, key2);
  });
  console.log(dayModule)
  let schemaKeys = Object.keys(daySchema);
  console.log(schemaKeys)
  schemaKeys.forEach(key=>{
    if(dayModule[key] == undefined){
      dayModule[key] = daySchema[key];
    }
  })
  console.log(dayModule)
  console.log("---")
  return dayModule
}


const IndexPage = ({data}) => {
  // console.log("data", data.googleSheet.fields.weekSheets);
  let d = data.googleSheet.fields.weekSheets

  console.log(d);
  let dates = d.map(item=>item.data[0].date);
  const now = new Date();
  let mostRecentDate = "1/1/1999";

  dates.forEach(function(d) {
    const date = new Date(d);

    if (date <= now && (date > new Date(mostRecentDate) || date > mostRecentDate)) {
      mostRecentDate = d;
    }
  });
  console.log("closes", mostRecentDate)



  let weekModules = {}
  d.forEach(dp=>{
    let active = dp.active;
    let dueOnly = dp.dueOnly;
    let type = dp.type;
    if(type == "dummy") return;
    let sheetdata = dp.data;

    let week, dayModule;
    if(active){
      let singleValues = ["week", "order", "type", "date"];
      let columnValues = ["topics", "due", "alerts"];
      let doubleColumnLinkValues = [["reading", "readingLinks"],["assignments", "assignmentsLinks"],["talkingPoints", "talkingPointsLinks"],["optionalReading", "optionalReadingLinks"], ["links", "linksLinks"]];
      dayModule = buildDayModule(sheetdata, singleValues, columnValues, doubleColumnLinkValues);
      week = sheetdata[0].week;
    }else if(dueOnly){
      let singleValues = ["week", "order", "type", "date"];
      let columnValues = ["due", "alerts"];
      let doubleColumnLinkValues = [];
      dayModule = buildDayModule(sheetdata, singleValues, columnValues, doubleColumnLinkValues);
      week = sheetdata[0].week;
    }
    dayModule.mostRecent = dayModule.date == mostRecentDate ? true:false;

    // console.log(dayModule)
    if(weekModules[week] == undefined){
      weekModules[week] = [dayModule];
    }else{
      weekModules[week].push(dayModule);
      weekModules[week].sort((a,b)=>{
        return a.order - b.order;
      })
    }



  });
  

  let weeknumbers = Object.keys(weekModules);
  weeknumbers.sort((a,b)=>a-b);
  weekModules = weeknumbers.map(week =>{
    return {week:week, data:weekModules[week]}
  })
  console.log(weekModules)


  return (
    <Layout>

      <FrontMatter/>
    
      <Schedule data={weekModules}/>

    </Layout>
  )
}



export default IndexPage

// export const query = graphql`
// query {
//   leonDataType {
//     data {
//       week
//       data {
//         alerts
//         date
//         due
//         order
//         talkingPoints {
//           link
//           text
//         }
//         assignments {
//           link
//           text
//         }
//         reading {
//           link
//           text
//         }
//         optionalReading {
//           link
//           text
//         }
//         links {
//           link
//           text
//         }
//         topics
//         type
//         week
//       }
//     }
//   }
// }
// `
// export const query = graphql`
// query {
//   googleSheet {
//     fields {
//       weekModules {
//         week
//         data {
//           alerts
//           assignments {
//             link
//             text
//           }
//           date
//           due
//           links {
//             link
//             text
//           }
//           optionalReading {
//             link
//             text
//           }
//           order
//           reading {
//             link
//             text
//           }
//           talkingPoints {
//             text
//           }
//           topics
//           type
//           week
//         }
//       }
//     }
//   }
// }
// `

export const query = graphql`
query {
  googleSheet {
    fields {
      weekSheets {
        active
        dueOnly
        type
        data {
          week
          type
          topics
          talkingPoints
          talkingPointsLinks
          readingLinks
          reading
          parent
          order
          optionalReadingLinks
          optionalReading
          linksLinks
          links
          id
          due
          date
          assignmentsLinks
          assignments
          alerts
          active
        }
      }
    }
  }
}
`
