import { Link } from "gatsby"
import React from "react"
import styles from "./frontmatter.module.css"

const FrontMatter = () => (
  <div className={styles.scope}>

    <div className={styles.descriptionWrapper}>
      <p className={styles.miniHeader}>description</p>
      <p>Welcome to the ABC Browser Circus (ABC), where acrobats juggle with hyperlinks, dance across scrolling grids and jump through open server ports.</p>
      <p>This course introduces the students to the history of the internet, the World Wide Web, and specifically to the browser as a cultural object and its role in (net)art; in parallel, it teaches web development and guides the students to create three web-based projects. Theory and practice-based components are each conducted during one of two 75 minute classes per week.</p>
      <p>Guest speakers may include artists that use the web as their primary medium as well as professionals working in curation and preservation of internet-based art.</p>
      <p>The practical component consists of three, consecutive parts (A,B & C, illustrated in the diagram below) that build on top of each other, are informed by the theory, and result each in one project.</p>
    </div>
    <div className={styles.imageWrapper}>
      <img src="abc-diagram.png"/>
    </div>
    <br></br>

    <div className={`${styles.listWrapper} ${styles.infoWrapper}`}>
      <p className={styles.miniHeader}>info</p>
      <ul>
        <li>Instructor: Leon Eckert, leon.eckert@nyu.edu</li>
        <li>Class Times: Tuesdays & Thursdays, 1:45pm-3:00pm (calendar invite on request)</li>
        <li>Class Location: AB 824</li>
        <li>Office Hours: Wednesdqys, 12:30 - 2:30 pm (<a target="_blank" href="https://calendar.google.com/calendar/u/0/selfsched?sstoken=UUE0X1AyMVlCNnpyfGRlZmF1bHR8ZTBmYjk2MTcyMjZkZmUwMzhjYTllN2IxMzlkMmQ4MTU">sign up</a>) (may be adjusted to best fit our timezones)</li>
        <li>Course GitHub: <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus">https://github.com/leoneckert/abc-browser-circus</a></li>
        <li>Official Syllabus (<a target="_blank" href="https://drive.google.com/file/d/1f5YjecNGMPkS3aGTcrAUhkz_807Rgk61/view?usp=sharing">NYU access</a>) (updated soon)</li>
        <li>Course Roadmap (<a target="_blank" href="https://drive.google.com/file/d/11H2eOIpXFUYCwCsajUedK2bVOV0pGeWR/view?usp=sharing">NYU access</a>)</li>

      </ul>
    </div>

    {/*
    <div className={`${styles.listWrapper} ${styles.infoWrapper}`}>
      <p className={styles.miniHeader}>assigned projects</p>
      <ul>
    */}

        {/*<li><Link to="projects/multiplicity">multiplicity</Link></li>*/}
        {/*<li><Link to="projects/time">time</Link></li>*/}
        {/*<li><Link to="projects/performance">performance</Link></li>*/}
        {/*<li>🎠 <Link to="projects/sculpture">a kind of sculpture</Link></li>*/}
        {/*<li><Link to="projects/bug">bug</Link></li>*/}
        {/*<li><Link to="projects/gift">gift</Link></li>*/}
        {/*<li>🎡 <Link to="projects/parasite">parasite</Link></li>*/}
        {/*<li><Link to="projects/riddle">riddle</Link></li>*/}
        {/*<li><Link to="projects/together">together</Link></li>*/}
        {/*<li>🎪 <Link to="projects/there">there</Link></li>*/}

    {/*
      </ul>
    </div>
    */}

    <div className={`${styles.listWrapper} ${styles.usefulWrapper}`}>

    <p className={styles.miniHeader}>useful links:</p>
    <ul>
      <li><Link to="other/submit-assignments">How to submit assignments.</Link></li>
      <li><Link to="other/project-library/">Project Library (in progress).</Link></li>
      <li><Link to="https://docs.google.com/presentation/d/1-vvU4ObeSVC9KQEtP8pVYIdty93sSuiYFNE9MHifNDY/present?slide=id.g9af16672fd_0_30">Mobile Page? You MUST include this</Link></li>



    </ul>
    </div>

    <div className={styles.schedulePointerWrapper}>
      <p>schedule</p>
      <p>⬇️</p>
    </div>

  </div>
)


export default FrontMatter
