// import { Link } from "gatsby"
import React from "react"
import Day from "./day";

import styles from "./week.module.css"


const Week = ({children, number, daysdata}) => {
  console.log("daysdata", daysdata)
  return (
    <div className={styles.weekContainer}>
      {/* <p className={styles.weeknumber}><span>week</span> {number}</p> */}
      <p className={styles.weeknumber}>{number}</p>
      {daysdata.map((day, index) => (
        <div key={index} className={styles.day}>
          <Day daydata={day}></Day>
        </div>
      ))}
      {children}
    </div>
  )
}



export default Week
