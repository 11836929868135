import { Link } from "gatsby"
import React from "react"
import styles from "./day.module.css"

const Day = ({children, daydata}) => {
    console.log("day", daydata)

    let makeDate = (datestring) => {

        let d = new Date(datestring);
        let day = d.toLocaleString("default", { weekday: "long" });
        let month = d.toLocaleString('default', { month: 'short' });
        let date = d.getDate();
        return day+", "+month+" "+date;
    }

    let listAlerts = (alerts) => {
        if(alerts!=null && alerts.length>0){
            return alerts.map((alert, index)=><p key={index} className={styles.alert}>{alert}</p>)
        }else{
            return ""
        }

    }
    let listDue = (due) => {
        if(due.length>0){
            return (<p className={styles.due}><span>Due today:</span> {due.join(", ")}</p>)
        }else{
            return ""
        }
    }

    let listTopics = (topics) => {
        if(topics!=null&& topics.length>0){
            return topics.map((topic, index)=><p key={index} className={styles.topic}>{topic}</p>)
        }else{
            return ""
        }
    }

    let listTalkingPoints = (talkingPoints) => {
        if(talkingPoints!=null&& talkingPoints.length>0){
            return talkingPoints.map((talkingPoint, index)=>{
                if(talkingPoint.link != null){
                    return(
                        <p key={index} className={styles.talkingPoint}><a target={talkingPoint.link.startsWith("http")?"_blank":""} href={talkingPoint.link} dangerouslySetInnerHTML={{__html: talkingPoint.text.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></a></p>
                    )
                }else{
                    return(
                        <p key={index} className={styles.talkingPoint} dangerouslySetInnerHTML={{__html: talkingPoint.text.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></p>
                    )
                }
            })
        }else{
            return ""
        }
    }

    let listLinks = (links) => {
        if(links!=null&& links.length>0){
            let elms = [];
            links.forEach((link, index)=>{
                console.log(link)
                elms.push( <a key={index} className={styles.link} target={link.link.startsWith("http")?"_blank":""} href={link.link}>{link.text}</a>)
                if(index<links.length-1) elms.push( ", " )
            })
            return <p className={styles.allLinks}><span>Links:</span> {elms}</p>
            // return links.map((link, index)=><a key={index} className={styles.link} target="_blank" href={link.link}>{link.text}{links.length-index==1?"":", "}</a>)
        }else{
            return ""
        }
    }



    let listLinkedItems = (items) => {
        return items.map((item, index)=>{
            if(item.link != null){
                return(
                    <p key={index} className={styles.assignmentItem}><a className={item.text.endsWith("DONE")?styles.strikethrough:""} target={item.link.startsWith("http")?"_blank":""} href={item.link}>{item.text.endsWith("DONE")?item.text.substring(0, item.text.length-5):item.text}</a>{item.text.endsWith("DONE")?" ✔️":""}</p>
                )
            }else{
                return(
                    <p key={index} className={styles.assignmentItem}>{item.text}</p>
                )
            }
        })
    }

    let listReading = (reading, optionalReading) => {
        if(reading.length > 0 || optionalReading.length>0){

            return <div className={styles.assignmentPadding}><div className={styles.assignmentWrapper}>to read/watch:</div></div>
        }else{
            return ""
        }
    }

    let listAssignments = (assignments, headerText) => {
        if(assignments.length > 0){
            return <div className={styles.assignmentPadding}><div className={styles.assignmentWrapper}><p className={styles.assignmentHeading}>{headerText}</p><div className={styles.assignmentItemWrapper}>{listLinkedItems(assignments)}</div></div></div>
        }else{
            return ""
        }
    }

    let buildAssignmentsAndReading = (assignments, reading, optionalReading) => {
        if(reading.length > 0 || optionalReading.length>0 || daydata.assignments.length>0){
            return (
                <div className={styles.contentRight}>
                    {listAssignments(assignments, "to do:")}
                    {listAssignments(reading, "to read/watch:")}
                    {listAssignments(optionalReading, "optional:")}

                </div>
            )
        }else{
            return ""
        }

    }

    return (
        <div className={[styles.dayWrapper, styles[daydata.type.replace("-", "").replace(" ", "")]].join(" ")} >
            <span id={daydata.mostRecent?"recent":""} className={daydata.mostRecent?styles.recentAnchor:""}></span>
            <div className={styles.header}>
                <p className={styles.type}>{daydata.type}</p>
                <div><p className={styles.dateCurrent}>{daydata.mostRecent?"most recent class":""}</p><p className={styles.date}>{makeDate(daydata.date)}</p></div>
            </div>

            {listAlerts(daydata.alerts)}
            {listDue(daydata.due)}
            <div className={styles.contentWrapper}>
                <div className={daydata.reading.length>0||daydata.optionalReading.length>0 || daydata.assignments.length>0?styles.contentLeft:styles.contentFull}>
                    {listTopics(daydata.topics)}
                    {listTalkingPoints(daydata.talkingPoints)}
                    {listLinks(daydata.links)}
                </div>
                {buildAssignmentsAndReading(daydata.assignments, daydata.reading, daydata.optionalReading)}
                {/* {listReading(daydata.reading, daydata.optionalReading)} */}
            </div>



        </div>
    )
}


export default Day
