import { Link } from "gatsby"
import React from "react"
import Week from "./week";
import SectionBanner from "./sectionBanner";

import styles from "./schedule.module.css"


const Schedule = ({data}) => {
    console.log(data);
    // let weekData = data.googleSheet.fields.weekModules;
    // let weekData = data.leonDataType==null?[]:data.leonDataType.data;
    let weekData = data;

    console.log(weekData)
    // from here: https://stackoverflow.com/a/4020842
    let maxWeek = Math.max.apply(Math, weekData.map(function(o) { return o.week; }))
    console.log(maxWeek);

    let listWeeks = (start, end) => {
        return weekData.filter(week=>(week.week>=start&&week.week<=end)).map((week, index) => (
            <Week key={index} number={week.week} daysdata={week.data}></Week>
        ))
    }
    function showA(minWeek){
        if(maxWeek < minWeek) return
        return (
            <div className={styles.sectionContaier}>
                <SectionBanner text="Part A"/>
                {listWeeks(1, 5)}
            </div>
        )
    }
    function showB(minWeek){
        if(maxWeek < minWeek) return
        return (
            <div className={styles.sectionContaier}>
                <SectionBanner text="Part B"/>
                {listWeeks(6, 8)}
            </div>
        )
    }
    function showC(minWeek){
        if(maxWeek < minWeek) return
        return (
            <div className={styles.sectionContaier}>
                <SectionBanner text="Part C"/>
                {listWeeks(9, 14)}
            </div>
        )
    }
    return (
        <div className={styles.scheduleContainer}>
            {/* <div className={styles.sectionContaier}>
                <SectionBanner text="Part A"/>
                {listWeeks(1, 5)}
            </div> */}
            {showA(1)}
            {showB(6)}
            {showC(9)}
            

        </div>
    )
}



export default Schedule
