import React from "react"

import styles from "./sectionBanner.module.css"


const SectionBanner = ({children, text}) => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.fadeBorder}>
        <p>{text}</p>
      </div>
    </div>
  )
}



export default SectionBanner
